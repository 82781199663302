<template>
  <div class="main-wrap" v-webTitle :data-title="textTitle">
    <!-- 通用顶部 -->
    <generalTop></generalTop>

    <!-- 中间内容区 -->
    <div class="center-wrap">
      <!-- 标签区 -->
      <div class="tab-wrap">
        <el-tabs @tab-click="handleSwitch">
          <el-tab-pane
            v-for="item in dataArry"
            :key="item.id"
            :label="item.label"
          >
            <ul
              class="tab-pane-level2"
              v-for="sub in item.children"
              :key="sub.id"
            >
              <li @click="getContent(sub.id)">{{ sub.label }}</li>
            </ul>
          </el-tab-pane>
        </el-tabs>
      </div>

      <!-- 面包屑区 -->
      <div class="breadcrumb-wrap">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/ServiceCenter/index' }"
            >服务中心</el-breadcrumb-item
          >
          <el-breadcrumb-item>{{ subMenu_1 }}</el-breadcrumb-item>
          <!-- <el-breadcrumb-item>{{ subMenu_2 }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ subMenu_3 }}</el-breadcrumb-item> -->
        </el-breadcrumb>
      </div>

      <!-- 文章区 -->
      <div class="article-wrap">
        <div class="title-wrap">
          <div class="title">
            <span>{{ content.title }}</span>
          </div>
          <div class="other">
            <span class="time">{{ content.createTime }}</span>
            <span>|</span>
            <span class="author">发布：{{ content.createBy }}</span>
          </div>
        </div>

        <div class="article-content-wrap">
          <div class="article-content" v-html="content.content"></div>
        </div>
      </div>
    </div>

    <!-- 底部区 -->
    <footbar></footbar>

    <!-- 侧边栏 -->
    <sidebar></sidebar>
  </div>
</template>

<script>
import generalTop from "../../components/GeneralTop/index";
import footbar from "../../components/Footbar/index";
import sidebar from "../../components/Sidebar/index";
import { getServiceData } from "@/api/ServiceCenter";
import { getListData } from "@/api/common";

export default {
  components: {
    generalTop,
    footbar,
    sidebar,
  },

  data() {
    return {
      // 页面标题
      textTitle: "服务中心",

      // 页面内容类型
      contentType: "",

      // 数据数组
      dataArry: [],

      // 面包屑菜单
      subMenu_1: "",
      // subMenu_2: "",
      // subMenu_3: "",

      // 栏目id
      id: null,

      // 文章内容
      content: "",
    };
  },

  mounted() {
    this.handleContent();
  },

  methods: {
    // 获取参数及初始化面包屑
    handleContent() {
      this.contentType = this.$route.query.type;

      const data = {
        parentName: this.contentType,
      };
      getServiceData(data).then((res) => {
        this.dataArry = res.data;
        // console.log(this.dataArry);

        this.subMenu_1 = this.contentType;
        this.id = this.dataArry[0].children[0].id;

        this.getContent(this.id);
      });
    },

    // 获得文章内容
    getContent(id) {
      const data = {
        columnId: id,
      };
      getListData(data).then((res) => {
        // console.log(res);
        if (res.code === 200 && res.data.length > 0) {
          this.content = res.data[0];
        } else {
          this.content = "";
        }
      });
    },

    // 标签切换
    handleSwitch(tab) {
      let temp = [];
      this.dataArry.forEach((item) => {
        if (item.label == tab.label) {
          temp = item.children;
          this.getContent(temp[0].id);
        }
      });
    },
  },
};
</script>

<style scoped>
.tab-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
}

::v-deep .el-tabs__nav-wrap::after {
  background-color: transparent;
}

::v-deep .el-tabs__active-bar {
  background-color: transparent;
}

::v-deep .el-tabs__item.is-active {
  color: #fff;
  background-color: #ff7800;
  border-radius: 10px;
}

::v-deep .el-tabs--top .el-tabs__item.is-top {
  padding-right: 30px;
  padding-left: 30px;
}

::v-deep .el-tabs__item {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}

::v-deep .el-tabs__content {
  padding: 30px 30px 0 30px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}

.tab-pane-level2 {
  display: block;
}

.tab-pane-level2 li {
  margin: 0 20px 20px 0;
  float: left;
  display: block;
  white-space: nowrap;
}

.tab-pane-level2 li:hover {
  cursor: pointer;
}

.active {
  color: #ff7800;
}

.article-wrap {
  padding-top: 20px;
}

.title {
  width: 480px;
}

.title span {
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}

.other {
  margin-top: 20px;
}

.other span:nth-child(2) {
  margin: 0 20px;
}

.other span {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #595959;
}

.article-content-wrap {
  margin-top: 40px;
}

.article-content span {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 3;
}

.content-img {
  display: flex;
  justify-content: center;
}
</style>
