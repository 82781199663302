<template>
  <div class="main-wrap" v-webTitle :data-title="textTitle">
    <!-- 通用顶部 -->
    <generalTop></generalTop>

    <!-- 中间区域 -->
    <div class="center-wrap">
      <ul>
        <li class="item" @click="gotoIntro(arryList[3].typeName)">
          <div class="item-wrap">
            <div class="left-corner"></div>
            <div class="right-corner"></div>
            <div class="title-wrap">
              <span>{{ arryList[3].typeName }}</span>
              <span>{{ arryList[3].content }} </span>
            </div>
          </div>
        </li>
        <li class="item">
          <img :src="serverURL + arryList[3].imgUrl" alt="" />
          <img src="../../assets/images/service-mask.png" alt="" />
        </li>
        <li class="item">
          <img :src="serverURL + arryList[2].imgUrl" alt="" />
          <img src="../../assets/images/service-mask.png" alt="" />
        </li>
        <li class="item" @click="gotoIntro(arryList[2].typeName)">
          <div class="item-wrap">
            <div class="left-corner"></div>
            <div class="right-corner"></div>
            <div class="title-wrap">
              <span>{{ arryList[2].typeName }}</span>
              <span>{{ arryList[2].content }} </span>
            </div>
          </div>
        </li>
        <li class="item" @click="gotoIntro(arryList[1].typeName)">
          <div class="item-wrap">
            <div class="left-corner"></div>
            <div class="right-corner"></div>
            <div class="title-wrap">
              <span>{{ arryList[1].typeName }}</span>
              <span>{{ arryList[1].content }} </span>
            </div>
          </div>
        </li>
        <li class="item">
          <img :src="serverURL + arryList[1].imgUrl" alt="" />
          <img src="../../assets/images/service-mask.png" alt="" />
        </li>
        <li class="item">
          <img :src="serverURL + arryList[0].imgUrl" alt="" />
          <img src="../../assets/images/service-mask.png" alt="" />
        </li>
        <li class="item" @click="gotoIntro(arryList[0].typeName)">
          <div class="item-wrap">
            <div class="left-corner"></div>
            <div class="right-corner"></div>
            <div class="title-wrap">
              <span>{{ arryList[0].typeName }}</span>
              <span>{{ arryList[0].content }} </span>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- 底部区 -->
    <footbar></footbar>

    <!-- 侧边栏 -->
    <sidebar></sidebar>
  </div>
</template>

<script>
import generalTop from "../../components/GeneralTop/index";
import footbar from "../../components/Footbar/index";
import sidebar from "../../components/Sidebar/index";
import { getServiceList } from "@/api/ServiceCenter";
import global from "@/utils/global.js";

export default {
  components: {
    generalTop,
    footbar,
    sidebar,
  },

  data() {
    return {
      // 页面标题
      textTitle: "服务中心",

      // 服务器路径
      serverURL: global.httpUrl,

      // 数据
      arryList: [],
    };
  },

  created() {
    this.getData();
  },

  methods: {
    // 跳转
    gotoIntro(name) {
      this.$router.push({
        path: "/ServiceCenter/introduce",
        query: { type: name },
      });
    },

    // 获取数据
    getData() {
      const data = {};
      getServiceList(data).then((res) => {
        this.arryList = res.data;
      });
    },
  },
};
</script>

<style scoped>
.center-wrap {
  margin: 45px auto 0;
  width: 85%;
}

.center-wrap ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.item {
  width: 50%;
  height: 440px;
}

.item:nth-child(1),
.item:nth-child(4),
.item:nth-child(5),
.item:nth-child(8) {
  background-color: #3e3e3e;
  padding: 15px;
  cursor: pointer;
}

.item:nth-child(1) .item-wrap,
.item:nth-child(4) .item-wrap,
.item:nth-child(5) .item-wrap,
.item:nth-child(8) .item-wrap {
  position: relative;
  width: 100%;
  height: 100%;
}

.item:nth-child(1) .item-wrap .left-corner,
.item:nth-child(4) .item-wrap .left-corner,
.item:nth-child(5) .item-wrap .left-corner,
.item:nth-child(8) .item-wrap .left-corner {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
}

.item:nth-child(1) .item-wrap .right-corner,
.item:nth-child(4) .item-wrap .right-corner,
.item:nth-child(5) .item-wrap .right-corner,
.item:nth-child(8) .item-wrap .right-corner {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}

.item:nth-child(1) .item-wrap .title-wrap,
.item:nth-child(4) .item-wrap .title-wrap,
.item:nth-child(5) .item-wrap .title-wrap,
.item:nth-child(8) .item-wrap .title-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 544px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item:nth-child(1) .item-wrap .title-wrap span:nth-child(1),
.item:nth-child(4) .item-wrap .title-wrap span:nth-child(1),
.item:nth-child(5) .item-wrap .title-wrap span:nth-child(1),
.item:nth-child(8) .item-wrap .title-wrap span:nth-child(1) {
  font-size: 41px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
}

.item:nth-child(1) .item-wrap .title-wrap span:nth-child(2),
.item:nth-child(4) .item-wrap .title-wrap span:nth-child(2),
.item:nth-child(5) .item-wrap .title-wrap span:nth-child(2),
.item:nth-child(8) .item-wrap .title-wrap span:nth-child(2) {
  margin-top: 40px;
  font-size: 17px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
  display: inline;
  width: 100%;
  text-align: justify;
}

.item:nth-child(2),
.item:nth-child(3),
.item:nth-child(6),
.item:nth-child(7) {
  position: relative;
  overflow: hidden;
}

.item:nth-child(2) img:nth-child(1),
.item:nth-child(3) img:nth-child(1),
.item:nth-child(6) img:nth-child(1),
.item:nth-child(7) img:nth-child(1) {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
}

.item:nth-child(2) img:nth-child(2),
.item:nth-child(3) img:nth-child(2),
.item:nth-child(6) img:nth-child(2),
.item:nth-child(7) img:nth-child(2) {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.6s;
}

.item:nth-child(2):hover img,
.item:nth-child(3):hover img,
.item:nth-child(6):hover img,
.item:nth-child(7):hover img {
  transform: scale(1.2);
}
</style>
