import request from '@/utils/request'

// 服务中心栏目类型
export function getServiceList(data) {
    return request({
        url: '/door/searchServerConfigList',
        headers: {
            isToken: false
        },
        method: 'post',
        data: data
    })
}

// 服务中心二级/三级菜单
export function getServiceData(data) {
    return request({
        url: '/door/getServerTreeselect',
        headers: {
            isToken: false
        },
        method: 'post',
        data: data
    })
}