import request from '@/utils/request'

// 获取列表数据
export function getListData(data) {
    return request({
        url: '/door/getNewslist',
        headers: {
            isToken: false
        },
        method: 'post',
        data: data
    })
}

// 新闻详情
export function getDetail(id) {
    return request({
        url: `/door/newsDetail/${id}`,
        headers: {
            isToken: false
        },
        method: 'get',
    })
}

// 活动报名
export function handleJoinActivity(data) {
    return request({
        url: '/door/joinActivity',
        headers: {
            isToken: false
        },
        method: 'post',
        data: data
    })
}